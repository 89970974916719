import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import WebUIArticles from 'components/Web_User_Interface/IntroWebUI';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='INSTAR Web User Interface' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='The web user interface allows an easy configuration and camera control through your default web browser like Chrome, Safari, Firefox, Internet Explorer.' image='/images/Search/QI_SearchThumb_WebUI_1080p.png' twitter='/images/Search/QI_SearchThumb_WebUI_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Auswahl/' locationFR='/fr/Web_User_Interface/1080p_Series/Overview/' crumbLabel="WebUI" mdxType="BreadCrumbs" />
    <WebUIArticles mdxType="WebUIArticles" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      